import React from 'react';
import './styles.css';
import profilePic from './images/profile.png'; // Import the image

// Import Font Awesome components and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faArrowDown } from '@fortawesome/free-solid-svg-icons';

const App = () => {

  return (
    <div className="homePage">
      <div className="firstPage">
        <div className="container">
          <div className="profile-picture">
            <img src={profilePic} alt="Profile" />
          </div>
          <h1 className="title">Elias Helfand</h1>
          <div className="links">
            <a href="https://www.instagram.com/elias.helfand" target="_blank" rel="noopener noreferrer" className="link" style={{ '--link-order': 0 }}>
              <div className="link-container">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
              <span>Instagram</span>
            </a>
            <a href="https://linkedin.com/in/elias-helfand" target="_blank" rel="noopener noreferrer" className="link" style={{ '--link-order': 1 }}>
              <div className="link-container">
                <FontAwesomeIcon icon={faLinkedin} />
              </div>
              <span>LinkedIn</span>
            </a>
            <a href="mailto:helfandelias@gmail.com" className="link" style={{ '--link-order': 2 }}>
              <div className="link-container">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <span>Email</span>
            </a>
          </div>
          <button className="scrollButton" onClick={() => document.getElementById('secondPage').scrollIntoView({ behavior: 'smooth' })}><FontAwesomeIcon icon={faArrowDown}/></button>
        </div>
      </div>

      <div id="secondPage" className="secondPage">
        <div></div>
        <div className='progressText'>
          Work in progress.
        </div>
        <div className="waves"></div>
      </div>
      
      <div className="copyright">© 2024 <a className="copyrightLink" href="https://helfand.co" target="_blank" rel="noopener noreferrer">Helfand</a>. All Rights Reserved.</div>
    </div>
  );
};

export default App;
